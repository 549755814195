<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__head">
        <div class="footer-mobile__logo">
          <img
            src="~/assets/img/logo/header-logo-pc.svg"
            alt="MIA SOFIA KIDS"
          >
        </div>
        <div class="footer__head-socials">
          <a
            v-if="linkTelegram"
            :href="linkTelegram"
            target="_blank"
            rel="nofollow"
            class="--btn-link footer-border-button"
          >
            <img
              src="~/assets/img/svg/social-media/telegram.svg"
              alt="telegram"
              width="28px"
              height="28px"
            />
          </a>
          <a
            v-if="linkWhatsapp"
            :href="linkWhatsapp"
            target="_blank"
            rel="nofollow"
            class="--btn-link footer-border-button"
          >
            <img
              src="~/assets/img/svg/social-media/whatsapp.svg"
              alt="whatsapp"
              width="28px"
              height="28px"
            />
          </a>
          <div class="--phone">
            <div class="footer__head-subtitle">Телефон:</div>
            <a
              v-if="linkPhoneNumber"
              :href="linkPhoneNumber"
              target="_blank"
              rel="nofollow"
              class="footer__head-title"
              @click="setYMGoalPhoneClick"
            >
              {{ phoneNumber }}
            </a>
          </div>
        </div>
        <div class="footer__head-address">
          <div class="footer__head-subtitle">Адрес:</div>
          <div class="footer__head-title">{{ address }}</div>
        </div>
        <div
          :class="{'--error': $v.email.$error}"
          class="footer__head-subscribe"
        >
          <input
            v-model="formEmail.email"
            type="text"
            placeholder="Ваша почта"
          >
          <button
            class="btn btn-primary"
            :class="{'--loading': isSendingSubscribe}"
            :disabled="isSendingSubscribe"
            @click="onSubscribe"
          >
            {{ isSendingSubscribe ? 'Секундочку...' : 'Подписаться' }}
          </button>
        </div>
      </div>
      <div class="footer__body">
        <div class="footer__body-logo">
          <img
            src="~/assets/img/logo/header-logo-pc.svg"
            alt="MIA SOFIA KIDS"
            width="201px"
            height="84px"
          >
        </div>
        <div class="footer__body-nav">
          <FooterNav
            v-if="footerMenu.length > 0"
            :footer-menu="footerMenu"
          />
        </div>
        <div class="footer__body-info">
          <div class="footer__body-info-item">
            <div class="footer__head-subtitle">Почта для связи:</div>
            <div class="footer__head-title">info@mia-sofia.ru</div>
          </div>
          <div
            v-if="schedule"
            class="footer__body-info-item"
          >
            <div class="footer__head-subtitle">График работы:</div>
            <div
              class="footer__head-title"
              v-html="schedule"
            />
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <div class="footer__bottom-rights">© MiaSofia, 2013-{{ currentYear }}</div>
        <div class="footer__bottom-disclaimer">
          Информация на сайте носит исключительно информационный характер и не является
          публичной офертой, определяемой положениями ст. 437 Гражданского кодекса РФ.
        </div>
        <div class="footer__bottom-socials">
          <a
            v-if="linkVk"
            :href="linkVk"
            rel="nofollow"
            target="_blank"
            class="--btn-link"
          >
            <img
              src="~/assets/img/svg/social-media/vk.svg"
              alt="telegram"
              width="28px"
              height="28px"
            />
          </a>
          <a
            v-if="linkDzen"
            :href="linkDzen"
            target="_blank"
            rel="nofollow"
            class="--btn-link"
          >
            <img
              src="~/assets/img/svg/social-media/dzen.svg"
              alt="whatsapp"
              width="28px"
              height="28px"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';
import { appConfigStore } from '#sitis/stores/modules/app-store';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { computed } from '#imports';
import type { Ref } from '#imports';
import { getPhoneNumberLink } from '~/utils/phoneNumber';
import { useCommonStore } from '~/store/common';
import type { ComputedRef } from 'vue';

export type FooterNavItem = {
	id: number,
	title: string,
	path: string
}
const { $api, $toast, $ym } = useNuxtApp();

const appConfig = appConfigStore();
const cmsStore = cmsContentStore();
const commonStore = useCommonStore();
const notificationModal = useNotificationModal();

const formEmail = reactive<{ email: string }>({
  email: ''
});

const currentYear: ComputedRef<number> = computed(() => {
  return new Date().getFullYear()
});

const linkVk = computed(() => appConfig?.config?.vkontakte || '');
const linkTelegram = computed(() => appConfig?.vars?.Telegram || appConfig?.config?.telegram || '');
const linkWhatsapp = computed(() => {
  const phone = appConfig?.vars?.Whatsapp || '';
  if (!phone) {
    return '';
  }
  return `https://api.whatsapp.com/send/?phone=${phone}`;
});
const linkDzen = computed(() => appConfig?.config?.facebook || '');

const phoneNumber = computed(() => appConfig?.vars?.['Телефон'] || '');
const linkPhoneNumber = computed(() => {
  const phone = appConfig?.vars?.['Телефон'] || '';
  return `tel:${getPhoneNumberLink(phone)}`;
});

const address = computed(() => appConfig?.vars?.['Адрес'] || '');
const schedule = computed(() => appConfig?.vars?.['График работы'] || '');

const cityName = computed(() => {
  return appConfig?.config?.city?.data?.prepositional || appConfig?.config?.city?.data?.city_name;
});

const footerMenu: Ref<FooterNavItem[]> = computed(() => (cmsStore?.menus?.footer?.items || []));

const $v = useVuelidate({
  email: {
    email: helpers.withMessage('Неверный формат', email),
    required: helpers.withMessage('Обязательно к заполнению', required)
  }
}, formEmail);

function setYMGoalPhoneClick () {
  if (typeof $ym === 'function') {
    $ym('reachGoal', 'phone_click');
  }
}

const isSendingSubscribe: Ref<boolean> = ref(false);
async function onSubscribe () {
  const isFormCorrect = await $v.value.$validate();
  if (!isFormCorrect) {
    return false;
  }

  isSendingSubscribe.value = true;

  const groups = await $api.agent.get('/subscribe/groups').then((res) => res?._data).catch(() => []);

  let errorMessages: string[] = [];
  const subscribePromises = groups.map(async (group: any) => {
    const body = {
      group_id: group.id,
      email: formEmail.email
    };
    try {
      await $api.agent.post('/subscribe', { body });
      return true;
    } catch (error: any) {
      let _message = error?.response?._data?.[0]?.message || error?.response?._data?.message || 'Ошибка сервера';
      errorMessages.push(_message);
      return false;
    }
  });

  await Promise.all(subscribePromises);

  if (errorMessages.length > 0) {
    isSendingSubscribe.value = false;
    notificationModal.openNotificationModal({
      title: "Ошибка",
      message: errorMessages.join(';<br/>')
    })
    return
  }

  formEmail.email = '';
  $v.value.$reset();
  isSendingSubscribe.value = false;
  if (typeof $ym === 'function') {
    $ym('reachGoal', 'new_subscriber');
  }

  notificationModal.openNotificationModal({
    title: "Успешно отправлено",
    message: "Подписка на новости успешно оформлена"
  })
}
</script>

<style lang="scss" scoped>
.footer {
	//margin-top: -40px;
	border-radius: 40px 40px 0 0;
	padding: 40px 0;
	box-sizing: border-box;
	border: 1px solid #EBEBEB;
	background: #FFF;
}

.footer__head {
	display: flex;
	padding-bottom: 35px;
	border-bottom: 1px solid #F4F3F1;

	& > * {
		width: calc(100% / 3);
	}

}

.footer__head-subtitle {
  display: block;
	color: var(--black);
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.footer__head-title {
  display: block;
	color: var(--black);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 140%;
}

.footer__head-socials {
	display: flex;
	align-items: initial;
	max-width: 366px;
	width: 100%;

	.--btn-link {
    margin-top: auto;
    margin-bottom: auto;
		margin-left: 10px;

		&:first-child {
			margin-left: 0;
		}
	}

	.--btn-number {
		color: var(--text-black, #000);
		font-size: 24px;
		font-weight: 600;
		cursor: pointer;
	}

	.--phone {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
		margin-left: 16px;
    margin-top: 6px;
	}
}

.footer-border-button {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 9.6px;
	padding: 6px;
	border: 1.2px solid var(--smoky-white, #EBEBEB);
	position: relative;

	img {
		width: 32px;
		height: 32px;
	}

	.--badge {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 4px;
		right: 4px;
		height: 15px;
		min-width: 15px;
		border-radius: 100%;
		color: var(--text-white, #FFF);
		font-size: 9.6px;
		font-weight: 500;

		&.--red {
			background-color: #ED908C;
		}

		&.--dark {
			background-color: #404040;
		}
	}

	&:hover {
		background: var(--smoky-white, #EBEBEB);
	}
}

.footer__head-address {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1;
  margin-top: 6px!important;
}

.footer__head-subscribe {
	padding: 3px 3px 3px 24px;
	border-radius: 12px;
	border: 1px solid #EBEBEB;
	display: flex;
	justify-content: space-between;
	max-width: 413px;
	width: 100%;
	margin-left: auto;
	height: 50px;

	& .btn {
		font-size: 12px;
		height: 44px;
	}

	&.--error {
		border-color: #ED908C;

		input {
			color: #ED908C;
		}
	}
}

.footer__body {
	display: flex;
	align-items: flex-start;
	padding-top: 40px;
	padding-bottom: 25px;
	border-bottom: 1px solid #F4F3F1;

	& > * {
		width: calc(100% / 3);
	}
}

.footer__body-logo {
	max-width: 366px;
	width: 100%;
}

.footer__body-nav {
	max-width: 565px;
	width: 100%;
}

.footer__body-info {
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: flex-end;
	margin-left: auto;

	&-item {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: 10px;
	}
}

.footer__bottom {
	padding-top: 25px;
	display: flex;
	align-items: flex-start;

	& > * {
		width: calc(100% / 3);
	}
}

.footer__bottom-socials {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
	flex: 1;

	& .--btn-link {
		width: 38px;
		height: 38px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #F4F3F1;
		border-radius: 8px;
		padding: 5px;
		transition: background .2s linear;

		&:hover {
			background: darken(#F4F3F1, 10%);
		}
	}
}

.footer__bottom-rights {
	font-weight: 500;
	max-width: 366px;
	width: 100%;
}

.footer__bottom-disclaimer {
	color: #989898;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	opacity: 0.5;
}

.footer-mobile__logo {
	display: none;
}

@media (max-width: 1699px) {
	.footer {
		padding-top: 38px;
	}
	.footer__head {
		padding-bottom: 41px;
	}
	.footer__body {
		padding-bottom: 23px;
	}
	.footer__bottom {

	}
	.footer__bottom-rights {
		max-width: 325px;
	}
	.footer__bottom-disclaimer {
		width: auto;
	}
	.footer__head-socials {
		max-width: 242px;
	}
	.footer__head-address {
		margin: 0 70px 0 82px;
	}
	.footer__body-logo {
		max-width: 325px;
	}
}

@media (max-width: 1359px) {
	.footer {
		padding: 32px 0;
	}
	.footer__head {
		align-items: flex-start;
		padding-bottom: 32px;
	}
	.footer__body {
		padding: 32px 0;
	}
	.footer__body-logo {
		max-width: 250px;
	}
	.footer__bottom-rights {
		max-width: 250px;
	}
	.footer__head-address {
		margin: 0 32px;
	}
	.footer__head-title {
		font-size: 14px;
	}
	.footer__head-subscribe {
		max-width: 320px;

		input {
			flex: 1;
			width: inherit;
		}
	}
}

@media (max-width: 1023px) {
	.footer__head-title {
		font-size: 16px;
	}

	.footer {
		border-radius: 30px 30px 0 0;
		padding: 40px 0;
	}
	.footer__head {
		flex-direction: column;
		align-items: center;
		padding-bottom: 39px;
	}
	.footer__head-socials {
		flex-wrap: wrap;
		flex-direction: row-reverse;
		align-items: initial;
		justify-content: center;
		order: 3;
		margin-top: 32px;
		max-width: initial;

		.--phone {
			display: flex;
			flex-direction: column;
      align-items: center;
      justify-content: center;
			width: 100%;
			margin-left: 0;
			order: 1;
		}

		.--btn-link {
			order: 2;
			margin-left: 10px !important;
		}

		.footer__head-subtitle {
			text-align: center;
		}

		.footer__head-title {
			text-align: center;
			margin-top: 12px;
			margin-bottom: 16px;
		}
	}
	.footer__head-address {
		align-items: center;
		order: 2;
		margin: 0;
		margin-top: 32px;
		width: 100%;

		.footer__head-title {
			text-align: center;
			margin-top: 12px;
			font-size: 16px;
		}
	}
	.footer__head-subscribe {
		max-width: initial;
		padding: 0;
		border: none;
		border-radius: 0;
		flex-direction: column;
		height: auto;
		order: 1;

		input {
			border-radius: 12px;
			border: 1px solid #B8B8B8;
			padding: 16px 24px;
			box-sizing: border-box;

			font-size: 12px;
			line-height: 14px;
			font-weight: 500;

			&::placeholder {
				color: #989898;
			}
		}

		.btn {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 10px;
			padding: 0;
			height: 48px;
			font-weight: 600;
		}
	}

	.footer__body {
		flex-direction: column-reverse;
		align-items: initial;
		padding-top: 40px;
	}
	.footer__body-logo {
		display: none;
	}
	.footer__body-info {
		flex-direction: row;
		align-items: initial;
		margin-left: 0;
		width: 100%;
		gap: 30px;
		margin-bottom: 40px;
	}
	.footer__body-info-item {
		flex: 1;
		align-items: initial;

		.footer__head-subtitle {
			text-align: left;
		}
	}

	.footer__bottom {
		flex-direction: column;
		padding-top: 39px;
	}
	.footer__bottom-rights {
		margin-bottom: 24px;
		max-width: initial;
	}
	.footer__bottom-disclaimer {
		margin-bottom: 24px;
	}
	.footer__bottom-socials {
		width: 100%;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.footer-mobile__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 258px;
		height: 110px;
		margin-bottom: 40px;

		img {
			width: 100%;
		}
	}
}
</style>
